import React from 'react';
import ReleventItemContainerLibrary from '@mshops-components-library/relevant-item-container';
import withLazyLoading from '../../adapters/withLazyLoading';
import { useRenderContext } from '../../pages/home/context';

const ReleventItemContainer = (props) => {
  const { isEshops, shopType, device } = useRenderContext();
  return <ReleventItemContainerLibrary {...props} isEshops={isEshops} eshopType={shopType} device={device} />;
};
export default withLazyLoading(ReleventItemContainer);
